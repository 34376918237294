<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="auto">
            <v-btn
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              @click="onBack()">
              <v-icon>
                mdi-chevron-left
              </v-icon>
              <span>
                ย้อนกลับ
              </span>
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="auto">
            <v-btn
              color="warning"
              depressed
              :ripple="false"
              style="width: fit-content;"
              @click="onEdit()">
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
              <span>
                แก้ไขข้อมูล
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg secondary--text">
          <v-col cols="12">
            <h3>
              ข้อมูลลูกค้า
            </h3>
            <v-divider class="mt-3" />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              เลขที่ลูกค้า
            </div>
            <v-text-field
              v-model="formData.id"
              placeholder="ALLXXXX-XXXXX"
              color="primary"
              outlined
              dense
              hide-details
              readonly
              required />
          </v-col>
          <v-col
            cols="12"
            md="6">
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              คำนำหน้า
            </div>
            <v-select
              v-model="formData.prefix"
              :items="prefixes"
              item-text="text"
              item-value="value"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              ชื่อ
            </div>
            <v-text-field
              v-model="formData.firstName"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              นามสกุล
            </div>
            <v-text-field
              v-model="formData.lastName"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              วัน เดือน ปีเกิด
            </div>
            <DatePickerDialog
              v-model="formData.birthDate"
              format="D MMMM BBBB"
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              หมายเลขโทรศัพท์
            </div>
            <v-text-field
              v-model="formData.tel"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              อีเมล
            </div>
            <v-text-field
              v-model="formData.email"
              type="email"
              :rules="textBoxRules"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
            </div>
            <v-text-field
              v-model="formData.citizenNo"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
          <v-col
            cols="12"
            md="6">
            <div class="mb-1">
              พอยท์ทั้งหมด
            </div>
            <v-text-field
              v-model="formData.points"
              color="primary"
              outlined
              dense
              hide-details
              required
              readonly />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row
          class="form-container rounded-lg secondary--text mt-1"
          align="center">
          <v-col
            cols="12"
            md="5">
            <v-tabs
              v-model="tab"
              class="menu-tabs"
              active-class="active-tab"
              hide-slider
              fixed-tabs>
              <v-tab>
                ประวัติการซื้อแพ็กเกจทัวร์
              </v-tab>
              <v-tab>
                พอยท์
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="auto">
            <DatePickerDialog
              v-model="date"
              type="month"
              format="MMMM BBBB"
              placeholder="เดือน ปี"
              clearable />
          </v-col>
          <v-col cols="12">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <order-list
                  v-if="tab === 0"
                  :month="date" />
              </v-tab-item>
              <v-tab-item>
                <point-list
                  v-if="tab === 1"
                  :month="date" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MembersProvider from '@/resources/members.provider'
import DatePickerDialog from '../../../components/DatePickerDialog.vue'
import OrderList from '../components/OrderList.vue'
import PointList from '../components/PointList.vue'

const MembersService = new MembersProvider()

export default {
  components: {
    DatePickerDialog,
    OrderList,
    PointList
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    prefixes: [
      {
        text: 'นาย',
        value: 'mr'
      },
      {
        text: 'นาง',
        value: 'ms'
      },
      {
        text: 'นางสาว',
        value: 'mrs'
      }
    ],
    formData: {
      email: '',
      password: '',
      prefix: 'mr',
      firstName: '',
      lastName: '',
      tel: '',
      imageUrl: '',
      birthDate: '',
      citizenNo: '',
      lineUid: '',
      fbUid: '',
      googleUid: '',
      totalOrders: 0,
      points: 0,
      pointExpire: null,
      rank: 'standard',
      channel: 'email'
    },
    tab: null,
    date: ''
  }),
  mounted () {
    this.getItemById()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'MemberList' })
    },
    onEdit () {
      this.$router.push({
        name: 'MemberEdit',
        params: {
          id: this.$route.params.id
        }
      })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await MembersService.getItemById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.menu-tabs {
  border: 1px solid #026EAA;
  border-radius: 8px;
  padding: 4px;
}
.active-tab {
  background-color: #026EAA;
  border-radius: 4px;
  color: white;
}
</style>
